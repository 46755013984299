import { stripRichTextWrapperTag } from '../../utils/html';

export default ({ primary, items }, pageData, { $enhancedLinkSerializer }) => {
  return {
    title: stripRichTextWrapperTag(primary.social_media_title),
    listItems: items.map((item) => ({
      label: item.label,
      icon: item.icon_name,
      link: $enhancedLinkSerializer(item.link),
    })),
  };
};
